import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import ContainerCard from 'src/components/ContainerCard';
import Statistic from 'src/components/Statistic';
import DataOverview from './components/DataOverview';
import LikeStat from './components/LikeStat';
import AppDistribution from './components/AppDistribution';

export default function Databoard() {
  const { t } = useTranslation();

  return (
    <div className="m-[16px] rounded-md min-[calc(100vh - 32px)]">
      <div className="flex xl:flex-row xxl:flex-row flex-col">
        <div className="xl:w-2/3 xxl:w-2/3 mr-[16px] w-full mb-[16px]">
          <ContainerCard title={t('databoard.total')}>
            <div className="py-[16px]">
              <DataOverview />
            </div>
          </ContainerCard>
        </div>
        <div className="xl:w-1/3 xxl:w-1/3 w-full mb-[16px] box-border flex flex-col">
          <ContainerCard title={t('databoard.today')} className="mb-[16px] h-2/5">
            <LikeStat />
          </ContainerCard>
          <ContainerCard title={t('databoard.distribution')} className="h-3/5">
            <AppDistribution />
          </ContainerCard>
        </div>
      </div>
      <Row gutter={16}>
        <Col span={6} lg={6} md={12} xs={24} className="mb-[16px]">
          <ContainerCard title={t('databoard.retention_trend')} className="max-h-[150px]">
            <Statistic
              statistic={{ value: 5670, trend: 'up', precision: '12%' }}
              chart={<div>312</div>}></Statistic>
          </ContainerCard>
        </Col>
        <Col span={6} lg={6} md={12} xs={24} className="mb-[16px]">
          <ContainerCard title={t('databoard.retention_count')} className="max-h-[150px]">
            <Statistic
              statistic={{ value: 4458, trend: 'up', precision: '20%' }}
              chart={<div>312</div>}></Statistic>
          </ContainerCard>
        </Col>
        <Col span={6} lg={6} md={12} xs={24} className="mb-[16px]">
          <ContainerCard title={t('databoard.month_add_order')} className="max-h-[150px]">
            <Statistic
              statistic={{ value: 4888, trend: 'down', precision: '18%' }}
              chart={<div>312</div>}></Statistic>
          </ContainerCard>
        </Col>
        <Col span={6} lg={6} md={12} xs={24} className="mb-[16px]">
          <ContainerCard title={t('databoard.month_add_user')} className="max-h-[150px]">
            <Statistic
              statistic={{ value: 1670, trend: 'down', precision: '24%' }}
              chart={<div>312</div>}></Statistic>
          </ContainerCard>
        </Col>
      </Row>
      <ContainerCard title={t('databoard.today_pv')}>
        <div>321</div>
      </ContainerCard>
    </div>
  );
}
