import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { numberGrow } from 'src/utils/tools';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa6';

interface IProps {
  title?: React.ReactNode;
  statistic?: {
    title?: React.ReactNode;
    value?: number;
    precision?: string;
    suffix?: string;
    description?: React.ReactNode;
    trend?: 'up' | 'down';
  };
  chart?: React.ReactNode;
  footer?: React.ReactNode;
}

export default function Statistic(props: IProps) {
  const { statistic, chart, footer, title } = props;
  const valueEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (statistic?.value && valueEl.current) {
      numberGrow(valueEl.current, statistic.value);
    }
  }, [statistic?.value]);

  return (
    <div className="mt-[4px]">
      {title && <div className="text-base my-[8px]">{title}</div>}
      {statistic && (
        <>
          <div className="">{statistic.title}</div>
          <div className="flex flex-col">
            <div className="text-2xl flex justify-start items-center">
              <div className="flex items-center">
                <div ref={valueEl}></div>
                <div className="ml-[4px]">{statistic.suffix}</div>
              </div>
              <div
                className={clsx(
                  'ml-[12px] text-sm flex items-center',
                  statistic.trend
                    ? statistic.trend === 'up'
                      ? `text-[#F5222D]`
                      : statistic.trend === 'down'
                        ? 'text-[#52C41A]'
                        : ''
                    : '',
                )}>
                {statistic.trend === 'up' && <FaCaretUp />}
                {statistic.trend === 'down' && <FaCaretDown />}
                {statistic.precision}
              </div>
            </div>
            <div className="text-sm">{statistic.description}</div>
          </div>
        </>
      )}
      {chart && <div className="">{chart}</div>}
      {footer && <div className="">{footer}</div>}
    </div>
  );
}
