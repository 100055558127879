import { useEffect, useRef } from 'react';
import { TbHandClick, TbHeart, TbTransform, TbEye } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { numberGrow } from 'src/utils/tools';

export default function DataOverview() {
  const { t } = useTranslation();

  const refPV = useRef<HTMLDivElement | null>(null);
  const refUV = useRef<HTMLDivElement | null>(null);
  const refCTR = useRef<HTMLDivElement | null>(null);
  const refMAU = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (refUV.current) numberGrow(refUV.current, 1902);
    if (refCTR.current) numberGrow(refCTR.current, 15610);
    if (refMAU.current) numberGrow(refMAU.current, 1240);
    if (refPV.current) numberGrow(refPV.current, 501656);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-around items-center sm:flex-row flex-col">
        <div
          className="sm:w-1/4 w-full mr-[8px] mb-[8px] p-[16px] rounded"
          style={{ backgroundImage: 'linear-gradient(135deg, #F3F3FE 10%, #DFE9FF 100%)' }}>
          <div className="text-sm font-bold mb-[8px] text-gray-900">{t('databoard.pv')}</div>
          <div className="flex justify-start items-center">
            <div className="w-[50px] h-[50px] rounded-full bg-[#DFE9FF] flex justify-center items-center mr-[16px]">
              <TbEye className="text-[#798EB7] text-2xl" />
            </div>
            <div className="text-lg font-bold text-gray-900" ref={refPV}></div>
          </div>
        </div>
        <div
          className="sm:w-1/4 w-full mr-[8px] mb-[8px] p-[16px] rounded"
          style={{ backgroundImage: 'linear-gradient(135deg, #F6FFFE 10%, #DFFEF7 100%)' }}>
          <div className="text-sm font-bold mb-[8px] text-gray-900">{t('databoard.uv')}</div>
          <div className="flex justify-start items-center">
            <div className="w-[50px] h-[50px] rounded-full bg-[#DFFEF7] flex justify-center items-center mr-[16px]">
              <TbHeart className="text-[#74D3C6] text-2xl" />
            </div>
            <div className="text-lg font-bold text-gray-900" ref={refUV}></div>
          </div>
        </div>
        <div
          className="sm:w-1/4 w-full mr-[8px] mb-[8px] p-[16px] rounded"
          style={{ backgroundImage: 'linear-gradient(135deg, #F7F3FB 10%, #F2E3FD 100%)' }}>
          <div className="text-sm font-bold mb-[8px] text-gray-900">{t('databoard.click')}</div>
          <div className="flex justify-start items-center">
            <div className="w-[50px] h-[50px] rounded-full bg-[#F2E3FD] flex justify-center items-center mr-[16px]">
              <TbHandClick className="text-[#9B7FB5] text-2xl" />
            </div>
            <div className="text-lg font-bold text-gray-900" ref={refCTR}></div>
          </div>
        </div>
        <div
          className="sm:w-1/4 w-full mb-[8px] p-[16px] rounded"
          style={{ backgroundImage: 'linear-gradient(135deg, #FFF9F1 10%, #FCF0DC 100%)' }}>
          <div className="text-sm font-bold mb-[8px] text-gray-900">{t('databoard.month')}</div>
          <div className="flex justify-start items-center">
            <div className="w-[50px] h-[50px] rounded-full bg-[#FCF0DC] flex justify-center items-center mr-[16px]">
              <TbTransform className="text-[#D0AC6B] text-2xl" />
            </div>
            <div className="text-lg font-bold text-gray-900" ref={refMAU}></div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
